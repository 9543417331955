.modalSuccess{
    position: fixed;
    width: 100%;
    height: 100%;
    display: none;
    &.active{
        display: flex;
    }
    z-index: 100;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
  
    .content{
        width: 100%;
        max-width: 300px;
        padding: 25px;
        border-radius: 25px;
        border: 2px solid rgba(114, 114, 114, 0.5);
        background: rgb(197,204,209);
        background: linear-gradient(309deg, rgba(197,204,209,1) 0%, rgba(238,240,241,1) 50%, rgba(200,207,212,1) 100%);
        position: relative;
        text-align: center;
        .btnClose{
            position: absolute;
            right: 10px;
            top: 10px;
            img{
                height: 20px;
            }
        }
        .check{
            display: inline-block;
            max-height: 90px;
        }
        h2{
            color: #152c3f;
            font-size: 1.15em;
            text-transform: uppercase;
            margin: 20px 0;

        }
    }
    .btnBrand{
        color: #284151;
        border: 2px solid rgba(200,159,68,0.60);
        padding: 8px 25px;
        font-weight: bold;
        border-radius: 5px;
        background: #C69B3E;
        background: repeating-linear-gradient(to right, #C69B3E 0%, #F9F6C2 25%, #C69B3E 50%, #F9F6C2 100%); 
        text-decoration: none;
        display: flex;
        gap: 10px;
        img{
            height: 20px;
        }
        span{
            display: inline-block;
        }
        &:hover{
            background: repeating-linear-gradient(to right, #C69B3E 0%, #F9F6C2 0%, #C69B3E 100%, #F9F6C2 100%); 
            animation: 0.2s all background;
        }
    }
}