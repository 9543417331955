//===========================================
// -Fonts start
//===========================================
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on June 20, 2023 */



@font-face {
    font-family: 'circular_air_problack';
    src: url('../fonts/circular_air_pro-black-webfont.woff2') format('woff2'),
         url('../fonts/circular_air_pro-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'circular_air_probold';
    src: url('../fonts/circular_air_pro-bold-webfont.woff2') format('woff2'),
         url('../fonts/circular_air_pro-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'circular_air_probook';
    src: url('../fonts/circular_air_pro-book-webfont.woff2') format('woff2'),
         url('../fonts/circular_air_pro-book-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'circular_air_prolight_italic';
    src: url('../fonts/circular_air_pro-light_italic-webfont.woff2') format('woff2'),
         url('../fonts/circular_air_pro-light_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'circular_air_prolight';
    src: url('../fonts/circular_air_pro-light-webfont.woff2') format('woff2'),
         url('../fonts/circular_air_pro-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'yaparibold';
    src: url('../fonts/yaparitrial-bold-webfont.woff2') format('woff2'),
         url('../fonts/yaparitrial-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'yapari_trialextra_light';
    src: url('../fonts/yaparitrial-extralight-webfont.woff2') format('woff2'),
         url('../fonts/yaparitrial-extralight-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'yapari_trialmedium';
    src: url('../fonts/yaparitrial-medium-webfont.woff2') format('woff2'),
         url('../fonts/yaparitrial-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'yapariregular';
    src: url('../fonts/yaparitrial-regular-webfont.woff2') format('woff2'),
         url('../fonts/yaparitrial-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}