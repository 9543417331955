header{
    padding: 10px;
    height: 60px;
    @include mq('phone','max'){
        padding: 0px;
    }
    .container{
        position: relative;
    }
    .logo{
      position: absolute;
      left: calc(50% - 40px);
      width: 80px;
       z-index: 10;
        img{
         

        }
    }
}

#banner{
    width: 100%;
    @include mq("tablet",min){
        background: url('../images/banner.jpg');
        background-size: cover;
        background-position: bottom center;

        .img-mobile{
            display: none;
        }
        height: calc(100vh - 70px);
    }
    
    position: relative;

    &:hover{
        .down{
            bottom: 10px;
        }
    }
    &::before{
      /*  content: "";
       position: absolute;
       width: 100%;
       left: 0;
       bottom: 0;
       height: 80%;
       @include mq('tablet','min'){
        height: 50%;
       }
       background: rgb(0,0,0);
        background: linear-gradient(0deg, rgba(0,0,0,1) 5%, rgba(255,255,255,0) 80%);
        */
    }
    .text{
        position: absolute;
        bottom: 50px;
        width: 90%;
        left: 5%;
        @include mq('tablet','min'){
            width: 40%;
            left: 30%;
        }
       
        color: #fff;
        text-align: center;

    }
    .down{
        background: #fff;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        color: $rosa;
        position: absolute;
        left: calc(50% - 25px);
        bottom: -25px;
        z-index: 999;
        text-align: center;
        line-height: 50px;
        vertical-align: middle;
        font-size: 1.5em;
        transition: 0.2s all linear;
        box-shadow: 5px 4px 19px #666;
       
    }
}

#personalize{
    padding: 50px 0;
    background: #f5f5f5;
    h2{
        color: $rosa;
    }
    .title{
        text-transform: uppercase;
        border-radius: 7px;
        padding: 5px 15px;
        border-left: 2px solid $rosa;
        border-right: 2px solid $rosa;
        font-size: 24px;
        text-align: center;
        font-family: $circular_probold;
        display: block;
        width: fit-content;
        margin: 0 auto 25px auto;
    }
    input{
        border: 1px solid $rosa;
        border-radius: 20px;
        width: 100%;
        padding: 5px 15px;
    }
   ul{
    display: flex;
    list-style: none;
    gap: 10px;
    margin: 20px 0 0 0;
    padding: 0;
    @include mq('phablet','max'){
    flex-wrap: wrap;
    }
    li{
        width: 100%;
       
            display: block;
         
            border-radius: 20px;
            border: 1px solid $rosa;
            position: relative;
           overflow: hidden;
           text-align: center;
            height: 125px;
           img{
            height: 90px;
            width: auto;
            @include mq('phablet','min'){
                    width: 100%;

                    height: auto;
                    }
           }
            input{
                position: absolute;
                opacity: 0;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                z-index: 10;
                &:checked + label{
                    background: #e8e6e6;
                }
            }
            label{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                padding: 25px;
            }
     

        &:hover{
            background: #f5f5f5;
           }
          
    }
   }
}

#localizacao{
    background: $rosa;
    color: #fff;
    padding: 50px 20px;
    @include mq('phablet','min'){
    padding: 50px;
    }
    position: relative;
    .container{
        position: relative;
        z-index: 10;
        h2{
            font-family: $circular_problack;
            font-size: 2.75em;
        }
        p{
            font-size: 1.3em;
            font-weight: 500;
        }
        .img{
            border-radius: 50px;
            padding: 15px;
            border-left: 5px solid #fff;
            border-right: 5px solid #fff;
            @include mq('phablet','min'){
                height: 350px;
            }
            width: fit-content;
            img{
                border-radius: 50px;
                max-height: 100%;
                width: auto;
            }
        }
    }
    &:after{
        content: "";
        position: absolute;
        background: url('../images/pin.svg');
        background-size: 50%;
        @include mq('phablet','min'){
            background-size: contain;
        }
        height: 90%;
        width: 100%;
        top: 5%;
        right: 0;
        background-repeat: no-repeat;
        background-position: right;
        z-index: 0;

        @include mq('phone','max'){
            height: 40%;
            top: 60%;
        }
    }
}

#localizacao2{
    background: $rosa;
    color: #fff;
    padding: 50px 20px;
    position: relative;
    .container{
        position: relative;
        z-index: 10;
        h4{
            font-family: $circular_problack;
            font-size: 2em;
            font-weight: bold;
            padding-left: 200px;

            @include mq('phone','max'){
                padding-left: 0px;
            }
        }
        p{
            font-size: 1.3em;
            padding-left: 50px;

            @include mq('phone','max'){
                padding-left: 0px;
            }
        }
        .img{
            border-radius: 50px;
            padding: 15px;
            border-left: 5px solid #fff;
            border-right: 5px solid #fff;
            @include mq('phablet','min'){
                height: 350px;
            }
            width: fit-content;
            img{
                border-radius: 50px;
                max-height: 100%;
                width: auto;
            }
        }
    }
    &:after{
        content: "";
        position: absolute;
        background: url('../images/pin.svg');
        background-size: 50%;
        @include mq('phablet','min'){
            background-size: contain;
        }
        height: 90%;
        width: 20%;
        top: 5%;
        left: 0;
        background-repeat: no-repeat;
        background-position: right;
        z-index: 0;
    }
}

#jeito{
    background: #f5f5f5;
    padding: 50px 0px;
    @include mq('phablet','min'){
    padding: 50px;
    }
    .card-body{
        padding: 25px;
        img{
            border-radius: 25px;
        }
        h2{
            font-family: $circular_problack;
            font-size: 2.3em;
            span{
                color: $rosa;
                display: block;
            }
        }
        ul:not(.slick-dots){
            display: flex;
            gap: 10px;
            list-style: none;
            margin: 25px 0 15px 0;
            padding: 0;
            li{
                width: 100%;
                border: 1px solid gray;
                border-radius: 20px;
                padding: 7px;
                text-align: center;
                font-size: 1em;
                @include mq('phablet','min'){
                    font-size: 1.3em;
                }
                font-family: $circular_probold;
                .numero{
                    font-size: 1.3em;
                    @include mq('phablet','min'){
                    font-size: 1.75em;
                    }
                    color: $rosa;
                    font-family: $circular_problack;
                }
            }
        }
        .slick-dots {
            bottom: -35px;
            li {
                &.slick-active{
                    button:before{
                        background: $rosa;
                    }
                }
                button:before{
                background: #d7d7d7;
                content: "";
                border-radius: 50%;
                height: 10px;
                width: 10px;
            }
        }
        }
    }
}

.texto{
    background: #f5f5f5;
    padding: 0px 0 50px 0;
}

footer{
    background: #1c1c1c;
    @include mq('phablet','min'){
        height: 35vh;
    }
    padding: 50px;
    img{
        height: 25px;
    }
    .img2{
        height: 50px;
        margin-top: -0.75rem;
    }
}


#cadastro{
    background: $rosa;
    color: #fff;
    margin-top: 150px;
    padding: 80px 50px 40px 50px;;
    position: relative;
    @include mq('phone','max'){
        margin-top: 100px;
    }
    .container{
        position: relative;
        z-index: 10;
        h1{
            font-family: $circular_problack;
            font-size: 2.75em;
        }
        p{
            font-size: 1.3em;
            font-weight: bold;
        }
        .img{
            border-radius: 50px;
            padding: 15px;
            border-left: 5px solid #fff;
            border-right: 5px solid #fff;
            @include mq('phablet','min'){
                height: 350px;
            }
            width: fit-content;
            img{
                border-radius: 50px;
                max-height: 100%;
                width: auto;
            }
        }
    }
    &:after{
        content: "";
        position: absolute;
        background: url('../images/urso.png');
        background-size: 80%;
        @include mq('phablet','min'){
            background-size: contain;
        }
        height: 190%;
        width: 100%;
        top: -40%;
        right: 17%;
        background-repeat: no-repeat;
        background-position: right;
        z-index: 0;
        @include mq('phone','max'){
            right: 0;
            left: 25%;
        }
    }
}


#investimento{
    background: #fff;
    color: #000;
    padding: 0px 0px 0px 200px;
    position: relative;

    @include mq('phone','max'){
        padding: 0px;
    }
        h3{
            font-family: $circular_problack;
            font-size: 2.5em;
        }
        p{
            font-size: 1em;
        }
        .img{
            float: right;
            @include mq('phablet','min'){
                height: 100%;
            }
            width: fit-content;
            img{
                height: 100%;
                width: auto;
            }

            &:after{
                content: "";
                position: absolute;
                height: 80%;
                width: 80%;
                top: 10%;
                left: 40px;
                z-index: 0;
                border-radius: 50px;
                padding: 15px;
                border-left: 5px solid #fff;
            }
        }
}

#final{
    padding: 50px;
}